<template>
  <scroll-layout class="details">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <ykc-search
      ref="YkcSearch"
      :data="searchData"
      :searchForm="searchParams"
      @clean="handleSearchClean"
      @click="handleSearchConfirm"></ykc-search>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <template #headerButtons>
        <div class="table-header-button">
          <ykc-button type="plain" v-rbac="'operation:evaluate:keyword:import'" @click="batchAdd">
            批量录入
          </ykc-button>
          <ykc-button
            v-rbac="'operation:evaluate:keyword:export'"
            type="plain"
            @click="doExportFile">
            导出
          </ykc-button>
        </div>
      </template>
      <ykc-pagination
        align="right"
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
    <!-- 批量导入 -->
    <ykc-operation-dialog
      destroy-on-close
      :show.sync="batchAddDialog"
      title="批量导入"
      size="medium"
      :on-close="onCloseBatchAdd"
      :before-cancel="beforeCancelBatchAdd"
      :before-confirm="beforeConfirmBatchAdd">
      <template slot="content">
        <ykc-form :model="batchAddForm" :rules="batchAddRules" ref="batchAddForm">
          <ykc-form-item class="file-error" label="选择文件" prop="file">
            <ykc-upload
              type="xlsx"
              :size="5"
              ref="ykcupload"
              :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
              @handleChange="uploadFile"
              @handleRemove="handleRemove"
              temText="屏蔽关键字导入模板"
              :temhref="temhref"></ykc-upload>
          </ykc-form-item>
        </ykc-form>
      </template>
    </ykc-operation-dialog>
  </scroll-layout>
</template>

<script>
  import { code, offlineExport } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { orderPage } from '@/service/apis';

  export default {
    data() {
      return {
        searchParams: {
          keywords: '',
        },
        tableTitle: '关键字列表',
        tableData: [],
        tableOperateButtons: [
          {
            enabled: () => code('operation:evaluate:keyword:delete'),
            render: (h, data, { row, $index }) => (
              <ykc-button type="text" onClick={() => this.delRow(row, $index)}>
                删除
              </ykc-button>
            ),
          },
        ], // 列表操作
        tableColumns: [
          { label: '关键字内容', prop: 'keywords', minWidth: '200px' },
          { label: '修改人', prop: 'modifiedPerson', minWidth: '200px' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '200px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        temhref: `${process.env.VUE_APP_OSS}/resource/%E5%B1%8F%E8%94%BD%E5%85%B3%E9%94%AE%E5%AD%97%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx`,
        batchAddForm: {
          file: [],
        },
        batchAddRules: {
          file: [{ required: true, message: '请上传文件', trigger: 'change' }],
        },
        batchAddDialog: false, // 批量导入弹窗
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'keywords',
            label: '关键字',
            placeholder: '请输入关键字',
          },
        ];
      },
    },
    created() {
      this.requestList();
    },
    methods: {
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        orderPage
          .evaluateKeywordsList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      delRow(row, index) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '确认删除？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            orderPage
              .deleteEvaluateKeyword({
                id: row.id,
              })
              .then(() => {
                this.$message.success('操作成功');
                if (
                  index === 0 &&
                  (this.pageInfo.current - 1) * this.pageInfo.size + (index + 1) ===
                    this.pageInfo.total &&
                  this.pageInfo.current > 1
                ) {
                  this.pageInfo.current--;
                }
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              })
              .finally(() => {
                dialogDone();
              });
          },
        });
      },
      // 批量录入
      batchAdd() {
        this.batchAddDialog = true;
      },
      onCloseBatchAdd() {
        this.batchAddForm.file = [];
        this.batchAddDialog = false;
      },
      beforeCancelBatchAdd(done) {
        this.batchAddForm.file = [];
        done();
      },
      beforeConfirmBatchAdd(done) {
        this.$refs.batchAddForm.validate(async valid => {
          console.log(valid);
          if (valid) {
            const formData = new FormData();
            const param = {
              file: this.batchAddForm.file.raw,
            };
            // 处理入参格式
            Object.entries(param).forEach(([k, v]) => {
              formData.append(k, v);
            });
            orderPage.evaluateKeywordsImport(formData).then(() => {
              this.requestList();
              done();
            });
            this.$refs.batchAddForm.clearValidate();
          }
        });
      },
      uploadFile(file, fileList, isCheck) {
        console.log(file, fileList, isCheck);
        if (isCheck) {
          this.batchAddForm.file = file;
          this.$refs.batchAddForm.clearValidate();
        }
      },
      handleRemove() {
        this.batchAddForm.file = [];
      },
      doExportFile() {
        offlineExport(
          {
            downloadType: 'keywords_export',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
    },
  };
</script>
